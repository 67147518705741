import React from "react";
import {Link} from 'gatsby'

import Layout from "../components/Layout";
import data from "../../content/resources/services.json";
import Caroucel from "../components/Caroucel";

const IndexPage = () => (
        <Layout>
            <div id="main">
                <Caroucel/>
                <div className="inner">
                    <section id="about_us">
                        <div className="home">
                            <h1 className="home__title">About us?</h1>
                        </div>

                        <div className="card">
                            <div className="card__content">
                                <h1 className="home__title_card">Tyriontech</h1>
                                <p className="home__parrafo_card">We are a high-tech software company, based in the city of
                                    queretaro, mexicoxas; with more than 20 years of experience in developing customized
                                    erps and crms.</p>
                                <Link to="/About" className="button">More information</Link>
                            </div>
                        </div>
                    </section>
                    {/*
                         <section id="">
                        <div className="home">
                            <h1 className="home__title">En que nos diferenciamos</h1>
                        </div>
                        <div className="blog-card">
                            <div className="meta">
                                <div className="photo"
                                     style={{backgroundImage: 'url(https://images.pexels.com/photos/2451646/pexels-photo-2451646.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)'}}>

                                </div>
                            </div>
                            <div className="description">
                                <p className="home__parrafo_card">Somos un despacho contable que utiliza
                                    herramientas
                                    tecnológicas innovadoras que te serán de gran utilidad para simplificar la
                                    administración de todos los procesos y registros contables. Contamos con una
                                    interfaz
                                    donde podrás visualizar y descargar de manera organizada a manera de directorio
                                    todos y
                                    cada uno de los comprobantes y acuses fiscales en sus versiones PDF y XML la
                                    cual podrás
                                    acceder con una cuenta de usuario que creamos especialmente para tu compañía.
                                    Con la
                                    misma cuenta también podrás subir los PDF de los estados de cuenta de todas y
                                    cada uno
                                    de los bancos relacionados con la contabilidad.</p>
                            </div>
                        </div>
                    </section>

                    */}
                    <section id="services">
                        <div className="home">
                            <h1 className="home__title">Services</h1>
                        </div>
                        <section className="tiles">
                            {data.slice(0, 6).map((service) => {
                                return (
                                    <article key={service.id} className="style1">
                                    <span className="image">
                                        <img
                                            src={service.image}
                                            alt={service.alt}/>
                                    </span>
                                        <Link state={{service: service}}>
                                            <h2>{service.name}</h2>
                                            <div className="content">
                                                <p>
                                                    {service.description}
                                                </p>
                                            </div>
                                        </Link>
                                    </article>
                                )
                            })}
                            {/*
                                <div style={{flex: 1, paddingTop: 20, textAlign: 'center'}}>
                                <Link to="/Services" className="button">Ver más productos</Link>
                            </div>
                            */}
                        </section>
                    </section>
                </div>
            </div>
        </Layout>
    )
;

export default IndexPage;
